import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAxUAS3F3Ag5h_QjmcPTR-N-XintcFKyBc",
  authDomain: "secret-santa-celebration.firebaseapp.com",
  projectId: "secret-santa-celebration",
  storageBucket: "secret-santa-celebration.appspot.com",
  messagingSenderId: "609529358057",
  appId: "1:609529358057:web:4f6f89f565a698f796b278",
  measurementId: "G-DVV63PVZLV",
};
const fire = firebase.initializeApp(firebaseConfig);

// const db = firebase.firestore();

export default fire;
