import fire from "./config/firebase";
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import santa from "./assets/santa.png";

function App() {
  const [members, setMembers] = useState([]);
  const memberRef = useRef(members);
  const [selectedMemberName, setSelectedMemberName] = useState("");
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const db = fire.firestore();
  const auth = fire.auth();

  useEffect(() => {
    authListener();
  });

  useEffect(() => {
    // console.log("currentUser in useEffect");
    // console.log(currentUser);
  }, [currentUser]);

  useEffect(() => {
    memberRef.current = members;
  }, [members]);

  useEffect(() => {
    // console.log("doc id in useEffect");
    // console.log(documentId);
  }, [selectedMemberName]);

  useEffect(() => {
    // console.log("doc id in useEffect");
    // console.log(documentId);
  }, [userEmailAddress]);

  const authListener = () => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      // console.log("email in storage");
      // console.log(email);
      // if (email === null) {
      //   email = window.prompt("Please provide your email for confirmation.");
      // }
      // console.log("email from user");
      // console.log(email);
      if (email !== null) {
        auth
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            window.localStorage.removeItem("emailForSignIn");
            // console.log("current user");
            // console.log(result.user);
            setCurrentUser(result.user);
          })
          .catch(function (error) {
            setCurrentUser(null);
            console.log(error);
          });
      }
    }
  };

  const getUserEmailAddress = (e) => {
    // console.log("user email id: ", e.target.value);
    setUserEmailAddress(e.target.value);
    // $(".showmembersId").prop("disabled", false);
  };

  const submitEmailAddress = () => {
    var actionCodeSettings = {
      url: "https://secretsanta.catalyst.sh/",
      // url: "http://localhost:3000/",
      // This must be true.
      handleCodeInApp: true,
    };
    // console.log(userEmailAddress);
    auth
      .sendSignInLinkToEmail(userEmailAddress, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", userEmailAddress);
        setUserEmailAddress("");
        alert("Please check your email to sign in!");
      })
      .catch((error) => {
        console.log(error);
        // Some error occurred, you can inspect the code: error.code
      });
  };

  // };
  const getMembersArray = () => {
    if ($("#members-name").val() !== "") {
      $(".members-dropdown").show();
      let docId = uuidv4();
      // console.log("docid: ", docId);
      // setDocumentId(docId);
      // console.log("state documentId: ", documentId);
      var pushOnlyArray = $("#members-name").val().split(", ");
      let membersData = [];
      let memberNames = [];
      let memberEmails = [];
      let totalMembers = pushOnlyArray.length / 2;
      // console.log("total members");
      // console.log(totalMembers);
      for (let i = 0; i < pushOnlyArray.length; i++) {
        if (i % 2 === 0) {
          memberNames.push(pushOnlyArray[i]);
        } else {
          memberEmails.push(pushOnlyArray[i]);
        }
      }
      for (let j = 0; j < totalMembers; j++) {
        // console.log(j);
        let memberData = {
          name: memberNames[j],
          email: memberEmails[j],
          assigned: false,
          partner: "",
          // mailSent: false,
        };
        membersData.push(memberData);
      }
      setMembers(membersData);
      var docRef = db.collection("secret_santa_draws").doc(docId);
      // console.log("user email");
      // console.log(auth.currentUser.email);
      docRef.set({
        user_email: auth.currentUser.email,
        secret_santa_id: docId,
        members: membersData,
      });
      generateRandomName(docRef);
      // updateNames(docRef);
      $("#members-name").val("");
      alert("Mails have been sent!");
    } else {
      alert("Please Add Some members names");
    }
  };

  const generateRandomName = (docRef) => {
    // console.log("generateRandomName() called");
    // console.log(memberRef.current);
    docRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        data["members"].forEach((selectedMember) => {
          // members.forEach((selectedMember) => {
          // console.log("mapping members");
          // console.log(selectedMember);
          // console.log("members in state");
          // console.log(memberRef.current);
          let namesToChooseFrom = [];
          //? if no partner then generate
          if (selectedMember["partner"] === "") {
            //? pick members to choose from
            memberRef.current.forEach((member) => {
              // console.log("mapping to choose possible partners");
              if (member["name"] !== selectedMember["name"]) {
                if (!member["assigned"]) {
                  namesToChooseFrom.push(member);
                } else {
                  // console.log("PARTNER ALREADY ASSIGNED");
                  // console.log(member["name"]);
                  // console.log(member["partner"]);
                }
              }
            });
            if (namesToChooseFrom.length > 0) {
              // console.log("namesToChooseFrom");
              // console.log(namesToChooseFrom);
              let totalNamesToChooseFrom = namesToChooseFrom.length;
              // console.log("totalnames: ", totalNamesToChooseFrom);
              let selectedMemberIndex = getRandomInt(0, totalNamesToChooseFrom);
              // console.log("selected member: ", selectedMemberIndex);
              let selectedMemberName =
                namesToChooseFrom[selectedMemberIndex]["name"];
              setSelectedMemberName(selectedMemberName);
              // console.log("selectedMemberName: ", selectedMemberName);
              memberRef.current.forEach((member) => {
                // console.log("inside foreach");
                if (
                  member["name"] ===
                  namesToChooseFrom[selectedMemberIndex]["name"]
                ) {
                  member["assigned"] = true;
                }
                if (member["name"] === selectedMember["name"]) {
                  member["partner"] =
                    namesToChooseFrom[selectedMemberIndex]["name"];
                }
              });
              // console.log("updated members");
              // console.log(memberRef.current);
              setMembers(memberRef.current);
              docRef
                .update({
                  members: memberRef.current,
                })
                .then(() => {
                  console.log("member set to assigned");
                })
                .catch((error) => {
                  console.log("Error updating document: ", error);
                });
            }
          }
          // });
        });
      }
    });
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const signOut = () => {
    auth
      .signOut()
      .then(function () {
        setCurrentUser(null);
        console.log("user sign out");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="app-wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Secret Santa Generator</title>
        <meta name="description" content="Using Secret Santa Generator, you can easily create Secret Santas for your team by putting their names and emails. They will receive an email with their partner's name. Safe and Secret!" />
      </Helmet>
      <header className="header">
        <h4>Secret Santa Generator</h4>
        {currentUser !== null ? (
          <div className="logout" onClick={signOut}>
            Logout
          </div>
        ) : (
          <div></div>
        )}
      </header>

      <div className="row ml-0 mr-0">
        {currentUser === null ? (
          <div className="col-sm-12 text-center">
            <div className="col-sm-12 text-center mt-5">
              <img className="santa" src={santa} alt="Secret Santa" />
              <h3>Enter your email address</h3>
            </div>
            <div className="col-sm-12 text-center mt-3 user-email-address">
              <input
                className="form-control"
                id="email-address"
                value={userEmailAddress}
                placeholder="name@example.com"
                onChange={getUserEmailAddress}
              />
              <button
                type="button"
                className="btn btn-info mt-3 yes"
                onClick={() => submitEmailAddress()}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="col-sm-12 text-center add-secret-key">
            <div className="form-group add-secret-santa text-center">
              <img className="santa" src={santa} alt="Secret Santa" />
              <h5>
                Add your team members’ name and email:
                <br /> eg: First Name, firstname@example.com
              </h5>
              <textarea
                className="form-control text-area"
                placeholder="First Name, firstname@example.com, Second Name, secondname@example.com"
                rows={5}
                id="members-name"
              ></textarea>
              <button
                type="button"
                className="btn btn-info mt-3 addMembers"
                onClick={getMembersArray}
              >
                Get Secret Santa
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="copyright">
        Developed by{" "}
        <a href="https://catalyst.sh/" target="_blank" rel="noreferrer">
          CT Solutions
        </a>{" "}
        &copy; {new Date().getFullYear()}
      </div>
    </div>
  );
}

export default App;
